import { useState } from 'react';
import { fetchJoinSessionLink } from '@src/api/index';
import { useLocation } from '@reach/router';

const useFetchSessionLink = () => {
  const [data, setData] = useState(null);
  const [errored, setErrored] = useState(null);

  const location = useLocation();

  const fetchJoinSessionLinkQuery = async (slug) => {
    try {
      const data = await fetchJoinSessionLink(slug, location.search);
      setData(data.data);
    } catch (e) {
      console.log(e);
      setErrored(true);
    }
  };

  return { fetchJoinSessionLinkQuery, data, errored };
};

export default useFetchSessionLink;
