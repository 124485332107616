import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CrioButton from '@components/v5/CrioButton/index';
import { cn } from '@/lib/utils';
import { StaticImage } from 'gatsby-plugin-image';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
};

const JoinSessionModal = ({ open, handleClose, subMessage, message }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="w-[300px] rounded-[20px] md:w-full md:max-w-[900px]"
        >
          <div className="flex w-full rounded-t-[20px] bg-[#57F49E] md:h-[220px] md:pr-[220px]">
            <div className="p-4 md:py-10 md:px-16">
              <h2 className="text-[20px] md:text-[44px]">Hi There!</h2>
              <p className="md:text-[24px]">
                If you’re seeing this, It means you’ve arrived here outside the
                session window.
              </p>
            </div>
            <div className="absolute top-0 right-0 hidden md:block">
              <StaticImage
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
                src="../../images/JoinSession/PersonWithLaptop.webp"
                placeholder="blurred"
                className="h-[220px] w-[220px]"
                alt="Hero"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-5 py-8 px-3 text-center md:gap-10 md:py-10">
            <div className="flex items-center gap-1 md:gap-3">
              <div className="hidden h-[30px] w-[24px] md:block">
                <StaticImage
                  loading="eager"
                  src="../../images/JoinSession/Clock.webp"
                  placeholder="blurred"
                  alt="About Us"
                  className="h-full w-full"
                />
              </div>
              <h3 className="text-[16px] md:text-[24px]">{message}</h3>
            </div>
            {subMessage && (
              <p className="font-manrope md:text-[18px]">{subMessage}</p>
            )}
            <div className="flex items-center justify-center">
              <CrioButton
                variant="crio-reboot"
                onClick={() => {
                  window.open('/', '_self');
                }}
                className={cn(`h-[64px] max-w-[270px] px-6`)}
              >
                <div className="pr-2 text-left">
                  <h6 className="font-extrabold">Continue to Homepage</h6>
                </div>
              </CrioButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default JoinSessionModal;
