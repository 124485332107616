import React from 'react';

const SessionInfoLabel = ({ label, value, icon }) => {
  return (
    <div className="flex flex-col items-center gap-2 text-center font-manrope md:flex-row">
      <div className="hidden h-6 w-6 md:block ">{icon}</div>
      <h3 className="text-[14px] font-extrabold md:text-[16px]">
        {label}
        <span className="hidden md:inline-block">:</span>
      </h3>
      <p className="text-[14px] font-medium md:text-[16px]">{value}</p>
    </div>
  );
};

export default SessionInfoLabel;
