import React from 'react';

const PaperClip = () => {
  return (
    <svg
      width="63"
      height="23"
      viewBox="0 0 63 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2103 21.6877C5.76212 21.5082 1.41848 16.9316 1.52442 11.4848C1.63036 6.03805 6.14862 1.63666 11.594 1.66898L52.7774 2.46151C57.6073 2.55545 61.315 6.35824 61.2224 11.1174C61.1298 15.8766 57.1902 19.777 52.4424 19.6846L21.9744 19.092C21.7396 19.0874 21.5463 19.2733 21.5418 19.5082C21.5372 19.743 21.7231 19.9363 21.9579 19.9408L52.4259 20.5335C57.6406 20.6349 61.9684 16.4194 62.0712 11.134C62.1203 8.61287 61.1814 6.23959 59.4273 4.45053C57.6815 2.67011 55.3262 1.66474 52.7938 1.61549L11.6048 0.822852C5.6925 0.784283 0.79043 5.56316 0.675575 11.4683C0.56072 17.3734 5.2732 22.3422 11.1881 22.5365L16.168 22.6333L16.1845 21.7845L11.2103 21.6877Z"
        fill="#07634C"
      />
    </svg>
  );
};

export default PaperClip;
