import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { cn } from '@/lib/utils';

/**
 * CrioLogo component renders the Crio.Do logo.
 * Parent Component: Navbar
 *
 * @param {boolean} dark - Flag indicating whether to render the dark version of the logo.
 */

export default function CrioLogo({ dark, className, containerClassName }) {
  return (
    <div className={containerClassName}>
      {/* Link to the homepage */}
      <Link to="/">
        <div>
          {/* Conditionally render dark or light logo based on the 'dark' prop */}
          {!dark ? (
            <StaticImage
              loading="eager"
              src="../../images/Crio_Dark.png"
              placeholder="none"
              alt="Crio.Do"
              className={cn('h-[40px] w-[100px] md:w-[130px]', className)}
              imgStyle={{ objectFit: 'contain' }}
            />
          ) : (
            <StaticImage
              loading="eager"
              src="../../images/Crio_Light.png"
              placeholder="none"
              alt="Crio.Do"
              className={cn('h-[40px] w-[100px] md:w-[130px]', className)}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </div>
      </Link>
    </div>
  );
}
